/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider.state('move-money.subaccount', {
    url: '/subaccount?id&type',
    templateUrl:
      'sections/move-money-subaccount/templates/acp-move-money-subaccount.ng.html',
    resolve: {
      module: /* @ngInject */ function($ocLazyLoad) {
        return import(/* webpackChunkName: "acp.section.move-money-subaccount" */ 'sections/move-money-subaccount').then(
          $ocLazyLoad.loadModule
        );
      }
    }
  });
}

export default states;
